import React from "react";
import { useIntersectionObserver } from "../../utils/intersectionObserverUtils";

const Intro: React.FC = () => {
  useIntersectionObserver("#IntroTitle", "left-slide-in");
  useIntersectionObserver("#IntroDes", "left-slide-in");

  return (
    <div className="page h-[600px] bg-blue-600 text-white">
      <div className="w-0 md:w-1/12"></div>
      <div
        id="IntroTitle"
        className="intro md:w-1/3 text-2xl sm:text-4xl md:text-6xl xl:text-8xl font-semibold leading-tight opacity-0"
      >
        <span className="intro-title">The </span>
        <span className="text-red-500 intro-title">App </span>
        <span className="intro-title">We're </span>
        <span className="intro-title">Making</span>
      </div>
      <div className="h-1/6 md:w-1/12 md:h-0"></div>
      <div
        id="IntroDes"
        className="intro md:w-2/3 text-xl sm:text-2xl md:text-4xl xl:text-5xl intro-description px-4 opacity-0"
      >
        When modern apps are filled with ads and overloaded with complicated
        features, we take a different approach by creating{" "}
        <span className="intro-point">simple</span>,{" "}
        <span className="intro-point">beautiful</span>, and{" "}
        <span className="intro-point">ad-free</span> apps.
      </div>
      <div className="w-0 md:w-1/12"></div>
    </div>
  );
};

export default Intro;
