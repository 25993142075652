import React from "react";
import FWWeb from "../assets/FW-web.png";
import Footer from "./Footer";
import { useIntersectionObserver } from "../utils/intersectionObserverUtils";

const About: React.FC = () => {
  useIntersectionObserver("#aboutDes", "left-slide-in");
  useIntersectionObserver("#aboutImg", "left-slide-in");

  return (
    <div className="flex-col my-0 py-0 bg-logo-blue">
      <div className="page h-[900px] text-white space-y-20 md:space-y-0">
        <img
          id="aboutImg"
          src={FWWeb}
          alt="Forger Wise Web Logo"
          className="w-[50%] sm:w-[40%] md:w-1/3 mx-auto opacity-0"
        />
        <div
          id="aboutDes"
          className="flex-col text-center space-y-8 md:space-y-20 w-full md:w-1/2 mx-auto opacity-0"
        >
          <div className="text-4xl md:text-6xl font-bold">About Me</div>
          <div className="text-2xl md:text-4xl italic">
            “The only ones who should code are those who are prepared to code.”
          </div>
          <div className="text-xs md:text-base">
            -- A developer who loves creating clean, beautiful, and ad-free
            software.
            <br />
            Currently studying at{" "}
            <span className="font-semibold">Osaka University</span>, with a
            passion for coding and technology.
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
