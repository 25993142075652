import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-transparent text-white p-4 text-center text-xs md:text-base">
      <p>© 2024 Forger Wise. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
