import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "swiper/css/bundle";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <ToastContainer position="bottom-right" pauseOnHover theme="colored" />
      <App />
    </React.StrictMode>
  );
}

reportWebVitals(console.log);
