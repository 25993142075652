import React, { useEffect, useState } from "react";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import logo from "../assets/FW-Logo-tr.png";
import { useNavigate } from "react-router-dom";

const NavigationBar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 480);
      if (window.innerWidth < 480) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavigate = (path: string) => {
    navigate(path);
    closeMenu();
    window.scrollTo(0, 0);
  };

  return (
    <nav className="bg-black p-4 shadow-md sticky top-0 z-50">
      <div className="flex items-center justify-between">
        <div className="w-0 sm:w-1/10"></div>
        <button className="sm:hidden relative -left-10" onClick={toggleMenu}>
          <MenuIcon className="h-6 w-6 text-white" />
        </button>
        <button
          onClick={() => handleNavigate("/")}
          className="flex items-center"
        >
          <img
            src={logo}
            alt="Logo"
            className="h-10 mr-2 ml-5 hidden sm:block md:ml-0 md:h-16 md:mr-4 lg:h-20 lg:mr-6"
          />
          <div className="text-white text-2xl mx-auto hover:text-gray-300 block sm:hidden md:block whitespace-nowrap">
            Forger Wise
          </div>
        </button>
        <div className="w-0 md:w-1/3 lg:w-1/2"></div>
        <div className="space-x-4 hidden sm:flex">
          <button className="nav-button" onClick={() => handleNavigate("/")}>
            Home
          </button>
          <button
            className="nav-button"
            onClick={() => handleNavigate("/About")}
          >
            About
          </button>
          <button
            className="nav-button"
            onClick={() => handleNavigate("/Contact")}
          >
            Contact
          </button>
        </div>
        <div className="w-0 sm:w-1/10"></div>
      </div>

      {/* menu */}
      <div
        className={`fixed top-0 right-0 w-full h-full bg-gray-900 z-100 text-white transform sm:hidden ${
          isMenuOpen && isSmallScreen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button className="absolute top-0 left-0 p-5" onClick={closeMenu}>
          <CloseIcon className="h-6 w-6 text-white" />
        </button>
        <div className="flex flex-col items-center justify-center h-full">
          <button
            className="nav-menu-button"
            onClick={() => handleNavigate("/")}
          >
            Home
          </button>
          <button
            className="nav-menu-button"
            onClick={() => handleNavigate("/About")}
          >
            About
          </button>
          <button
            className="nav-menu-button"
            onClick={() => handleNavigate("/Contact")}
          >
            Contact
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
