import React from "react";
import GitHubLogo from "../assets/github-mark-white.svg";
import EmailIcon from "../assets/mail-icon.svg";
import { FORGERWISE_GITHUB, FORGERWISE_EMAIL } from "../config";
import Footer from "./Footer";
import { useIntersectionObserver } from "../utils/intersectionObserverUtils";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

const Contact: React.FC = () => {
  useIntersectionObserver("#contact", "fade-in");

  // * Copy email address to clipboard and notify user
  const copyToClipboard = () => {
    let copyText = FORGERWISE_EMAIL;
    let isCopy = copy(copyText);
    if (isCopy) {
      toast.success("Email copied to clipboard!");
    } else {
      toast.error("Failed to copy email to clipboard.");
    }
  };

  return (
    <div className="flex flex-col my-0 py-0 bg-gradient-to-r from-gray-800 via-black to-gray-900">
      <div
        id="contact"
        className="flex flex-col h-[900px] md:h-[1000px] text-white space-y-20 opacity-0"
      >
        <div className="h-0 md:h-1/6"></div>
        <div className="text-center mx-10 md:mx-20 space-y-12">
          <h1 className="text-xl md:text-3xl font-bold">Contact</h1>
          <p className="mt-2 text-sm md:text-lg mx-auto">
            If you have any questions or suggestions, or if you would like to
            learn more about my work, please feel free to contact me or explore
            further through the links below.
          </p>
        </div>

        <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 justify-around items-center">
          <a
            href={FORGERWISE_GITHUB}
            target="_blank"
            rel="noopener noreferrer"
            className="contact-logo flex flex-col items-center transition-transform transform hover:scale-110"
          >
            <img
              src={GitHubLogo}
              alt="Forger Wise GitHub Link"
              className="w-1/6 md:w-1/5 mx-auto"
            />
            <div className="mt-2 md:mt-4 text-base md:text-xl">My GitHub</div>
          </a>
          <button
            onClick={copyToClipboard}
            className="contact-logo flex flex-col items-center transition-transform transform hover:scale-110"
          >
            <img
              src={EmailIcon}
              alt="Forger Wise Email Link"
              className="w-1/6 md:w-1/4 mx-auto"
            />
            <div className="mt-2 md:mt-0 text-base md:text-xl">Copy Email</div>{" "}
          </button>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
