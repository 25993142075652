import React from "react";

export function useIntersectionObserver(
  targetSelector: string,
  animationClass: string
) {
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(animationClass);
          entry.target.classList.remove("opacity-0"); // Remove opacity for smooth transition
          observer.unobserve(entry.target); // Stop observing after animation
        }
      });
    });

    const target = document.querySelector(targetSelector);
    if (target) {
      observer.observe(target);
    } else {
      console.warn(`Element with selector "${targetSelector}" not found.`);
    }

    // Cleanup function to disconnect the observer when component unmounts
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [targetSelector, animationClass]); // Dependencies
}
