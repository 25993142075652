import React from "react";
import LabelIcon from "@mui/icons-material/Label";
import LockIcon from "@mui/icons-material/Lock";
import { useIntersectionObserver } from "../../utils/intersectionObserverUtils";

const WiserLocker: React.FC = () => {
  useIntersectionObserver("#WiserLockerLabel", "bottom-slide-in");
  useIntersectionObserver("#WiserLocker", "bottom-slide-in");
  useIntersectionObserver("#WiserLockerLock", "bottom-slide-in");

  return (
    <div className="flex flex-col justify-center items-center w-full h-full p-4 bg-black text-white space-y-80 py-10">
      <div id="WiserLockerLabel" className="opacity-0">
        <LabelIcon />
      </div>
      <div
        id="WiserLocker"
        className="text-2xl md:text-3xl lg:text-4xl font-bold text-center items-center flex opacity-0"
      >
        Wiser Locker...... Coming soon
      </div>
      <div id="WiserLockerLock" className="opacity-0">
        <LockIcon />
      </div>
    </div>
  );
};

export default WiserLocker;
