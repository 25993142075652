import React from "react";
import foodlistLogo from "../../assets/FoodList-Logo-0.png";
import githubMark from "../../assets/github-mark.svg";
import FoodListDemo from "../../assets/FoodList-Demo.png";
import GooglePlayMark from "../../assets/GooglePlay-mark.png";
import { FOODLIST_APK, FOODLIST_PLAYSTORE } from "../../config";
import { useIntersectionObserver } from "../../utils/intersectionObserverUtils";

const FoodList: React.FC = () => {
  useIntersectionObserver("#FoodListTitle", "bottom-slide-in");
  useIntersectionObserver("#FoodListDes", "bottom-slide-in");
  useIntersectionObserver("#FoodListDL", "bottom-slide-in");
  useIntersectionObserver("#FoodListDemo", "bottom-slide-in");

  return (
    <div className="flex lg:items-center justify-center w-full h-full flex-col lg:flex-row bg-gradient-to-br from-FoodList-color to-blue-200 pb-20">
      <div className="w-0 lg:w-1/12"></div>
      <div className="flex flex-col w-full lg:w-3/5 pt-8 lg:pt-4 space-y-8 sm:space-y-16 md:space-y-24 lg:space-y-36">
        <div
          id="FoodListTitle"
          className="flex-1 flex items-center justify-center lg:justify-normal pt-4 opacity-0"
        >
          <img
            src={foodlistLogo}
            alt="FoodList Logo"
            className="h-10 w-auto block"
          />
          <div className="font-bold ml-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl">
            FOODLIST
          </div>
        </div>
        <div
          id="FoodListDes"
          className="flex-3 text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-center lg:text-left whitespace-nowrap opacity-0"
        >
          Keep it Fresh,
          <br />
          Save the Rest
        </div>
        <div
          id="FoodListDL"
          className="flex-1 flex text-left justify-center lg:justify-start p-0 m-0 opacity-0"
        >
          <a
            href={FOODLIST_PLAYSTORE}
            target="_blank"
            rel="noopener noreferrer"
            className="items-center transition-transform duration-300 ease-in-out hover:scale-110"
          >
            <img
              src={GooglePlayMark}
              alt="Download from Google Play"
              className="object-contain h-12 md:h-16 lg:h-20 mr-4 sm:mr-6 md:mr-8 lg:mr-10"
            />
          </a>
          <a
            href={FOODLIST_APK}
            target="_blank"
            rel="noopener noreferrer"
            className="items-center inline-flex transition-transform duration-300 ease-in-out hover:scale-110"
          >
            <button className="h-12 md:h-16 lg:w-[280px] lg:h-[80px] border-2 !border-black p-1.5 flex rounded-lg items-center justify-center">
              <img
                src={githubMark}
                alt="Download from GitHub"
                className="object-contain h-6 md:h-10 lg:h-14 mr-4"
              />
              <div className="flex-col flex">
                <div className="my-0 py-0 text-xs sm:text-sm lg:text-base">
                  Get APK on
                </div>
                <div className="my-0 py-0 font-bold text-sm sm:text-base lg:text-lg">
                  GitHub
                </div>
              </div>
            </button>
          </a>
        </div>
      </div>
      <div className="w-0 lg:w-1/12"></div>
      <div
        id="FoodListDemo"
        className="flex w-full lg:w-1/5 justify-center lg:justify-normal opacity-0"
      >
        <img
          src={FoodListDemo}
          alt="FoodList Demo"
          className="object-contain w-80 h-auto py-11 px-4 md:px-0"
        />
      </div>
      <div className="w-0 lg:w-1/12"></div>
    </div>
  );
};

export default FoodList;
